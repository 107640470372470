<template>
  <div class="main-contain">
    <div class="check-group">
      <el-radio-group v-model="radio" @change="changebt">
        <el-radio :label="0">全部</el-radio>
        <el-radio :label="1">仅老师参与</el-radio>
        <el-radio :label="2">回复最多</el-radio>
      </el-radio-group>
    </div>
    <div style="display: flex; justify-content: flex-end; margin-top: -15px">
      <el-button
        type="primary"
        style="background: #3d84ff"
        @click="add_interactive"
        >新增问答</el-button
      >
    </div>
    <div class="item" v-for="(item, index) in qadata" :key="index">
      <div class="title" @click="openreplypanel(item)">
        {{ item.title }}
        <span v-if="item.is_top === 1" class="item-two-bt item-two-bt-active"
          >置顶</span
        >
        <span v-if="item.is_teacher_join === 1" class="item-two-bt"
          >教师参与</span
        >
        <span
          v-if="Number(item.has_publish) === 0"
          class="item-two-bt item-two-bt-active-1"
          >待审核</span
        >
      </div>
      <div class="text">
        <div v-html="item.content"></div>
      </div>
      <div class="bottom-contain" v-if="Number(item.has_publish) === 1">
        <div class="bottom-left">
          <el-image class="elimage" :src="item.avatar">
            <template slot="error">
              <el-image :src="avater1"></el-image>
            </template>
          </el-image>
          <div class="teach">{{ item.author_name }}</div>
          <div class="time">{{ item.created_at }}</div>
          <div class="time">发布</div>
        </div>
        <div class="bottom-right">
          <div class="bottom-right-item">
            <img class="elimage1 elimage-icons" :src="view_d" />
            <div class="num">{{ item.view_count }}</div>
          </div>
          <div class="line"></div>
          <div class="bottom-right-item" @click="setlikebt(item.id)">
            <template v-if="item.like_status === 0">
              <img class="elimage2 elimage-icons" :src="like_d" />
              <div class="num">{{ item.like_count }}</div>
            </template>
            <template v-if="item.like_status === 1">
              <img class="elimage2 elimage-icons" :src="like_c" />
              <div class="num">{{ item.like_count }}</div>
            </template>
          </div>
          <div class="line"></div>
          <div class="bottom-right-item" @click="openreplypanel(item)">
            <img class="elimage3 elimage-icons" :src="review_d" />
            <div class="num">{{ item.reply_count }}</div>
          </div>
        </div>
      </div>
      <div :class="index % 2 === 0 ? 'line3' : ''"></div>
    </div>
    <!-- 分页 -->
    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        :current-page="queryParams.page"
        @current-change="pageChange"
      ></el-pagination>
    </div>

    <Customdialog
      ref="customdialog"
      :type="'max'"
      width="50%"
      title="互动问答回复"
      :showclose="true"
      @isDialogClose="closereplypanel"
    >
      <div slot="dialogbody" class="dialogbody">
        <Interactiveanswerreply
          ref="replypanel"
          v-if="isFlag"
          @childReset="initNewData"
        ></Interactiveanswerreply>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import { autoImgCorrection } from "@/utils/index";
import { getQaList, setQaLike } from "@/api/qa";
import dayjs from "dayjs";
import Interactiveanswerreply from "./interactiveanswerreply";
import pagTable from "@/components/Pagination/pagTable";
import Customdialog from "@/components/customdialog.vue";
const cityOptions = ["仅教师参与", "回复最多"];
export default {
  name: "virtualexperiment",
  components: {
    pagTable,
    Customdialog,
    Interactiveanswerreply,
  },
  data() {
    return {
      courseKey: "",
      radio: 0,
      testimage: require("@/assets/test.png"),
      view_d: require("@/assets/查看_d.png"),
      like_c: require("@/assets/点赞_c.png"),
      like_d: require("@/assets/点赞_d.png"),
      review_d: require("@/assets/评论_d.png"),
      avater1: require("@/assets/学生.png"),
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: true,
      qadata: [],
      total: 0,
      queryParams: {
        keyword: "",
        belong_type: 0,
        type: 0,
        related_type: 1,
        related_id: "",
        per_page: 10, // 每页条数
        page: 1,
      },
      isFlag: false,
      infoData: {},
    };
  },
  created() {},
  methods: {
    // 初始化数据
    initdata(obj, courseKey) {
      console.log("问答讨论", obj, courseKey);
      if (courseKey) {
        this.infoData = obj;
        this.courseKey = courseKey;
        this.getQAList();
      }
    },
    initNewData(data) {
      if (data) {
        this.initdata(this.infoData,this.courseKey);
      }
    },
    // 获取问答列表
    async getQAList() {
      this.qadata = [];
      this.queryParams.course_semester_sn = this.courseKey;
      let params = this.queryParams;
      await getQaList(params)
        .then((response) => {
          if (response && response.code === 0) {
            this.total = response.data.total;
            let newData = response.data.data;
            if (newData && newData.length > 0) {
              newData.forEach((item) => {
                item.content = autoImgCorrection(item.content);
                item.created_at = dayjs
                  .unix(item.created_at)
                  .format("YYYY-MM-DD HH:mm:ss");
              });
              this.qadata = newData;
            }
          }
        })
        .catch((error) => {
          //console.log(error)
        });
    },
    pageChange(val) {
      this.queryParams.page = val;
      this.getQAList(this.queryParams);
    },
    // 打开对话框
    openreplypanel(item) {
      this.isFlag = true;
      this.$refs.customdialog.dialogopenbt();
      this.$nextTick(() => {
        this.$refs.replypanel.initdata(item);
      });
    },
    // 关闭对话框
    closereplypanel() {
      this.isFlag = false;
    },
    toreply(item) {
      let obj = JSON.stringify(item);
      this.$router.push({
        path: "/home/virtualsimulationexperiment/interactiveanswerreply",
        query: {
          id: this.vr_id,
          item: encodeURIComponent(obj),
        },
      });
    },
    changebt(value) {
      this.queryParams.type = parseInt(value);
      this.getQAList();
    },
    setlikebt(id) {
      let params = { id: id };
      setQaLike(params)
        .then((response) => {
          this.queryParams.type = 0;
          setTimeout(() => {
            this.getQAList();
          }, 1500);
        })
        .catch((error) => {
          //console.log(error)
        });
    },
    add_interactive() {
      // 跳转
      this.$router.push({
        path: "/home/personalcenter/courseinteractiveqa",
        query: { course_semester_sn: this.courseKey },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  width: 100%;
  margin-top: 20px;
  // margin-left: 40px;
  // margin-right: 10px;
  overflow-y: auto;

  // min-height: 460px;
  .check-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;

    .check-all {
      margin-right: 20px;
    }
  }

  .item {
    padding-top: 20px;

    margin-top: 10px;

    background: #ffffff;

    .title {
      font-size: 20px;
      font-weight: 400;
      color: #222222;
      display: flex;
      cursor: pointer;

      .item-two-bt {
        width: 60px;
        min-width: 60px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        padding: 0 2px;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
        color: #409eff;
        background: #ffffff;
        border: 1px solid #409eff;
        border-radius: 4px;
        margin-top: 3px;
        margin-left: 10px;
      }

      .item-two-bt-active {
        color: #0fbf7f;
        width: 40px;
        min-width: 40px;
        border: 1px solid #0fbf7f;
      }
      .item-two-bt-active-1 {
        color: #f13218;
        width: 40px;
        min-width: 40px;
        border: 1px solid #f13218;
      }
    }

    .item-two {
      margin-top: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .text {
      color: #222222;
      font-size: 15px;
      font-weight: 400;
      margin-top: 10px;
      word-break: break-word; /* 英文-字母 */
      word-wrap: break-word; /* 英文-单词 */
      white-space: pre-line; /* 中文 */
    }

    .bottom-contain {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bottom-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .teach {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }

        .time {
          margin-right: 10px;
          font-size: 12px;

          font-weight: 400;
          color: #a0a0a0;
        }
      }

      .bottom-right {
        // padding-right: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bottom-right-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          // .elimage1 {
          //   width: 22px;
          //   height: 16px;
          // }

          // .elimage2 {
          //   width: 19px;
          //   height: 21px;
          //   margin-bottom: 4px;
          // }

          // .elimage3 {
          //   width: 19px;
          //   height: 16px;
          // }

          .num {
            margin-left: 6px;
            font-size: 15px;

            font-weight: 400;
            color: #a0a0a0;
          }
        }

        .line {
          margin-left: 20px;
          margin-right: 20px;
          height: 15px;
          border-right: 2px solid #e8e8e8;
        }
      }
    }

    .line3 {
      border-bottom: 1px solid #e8e8e8;
      margin-top: 15px;
      // margin-right: 60px;
    }
  }

  .elimage-icons {
    width: 20px;
    height: 20px;
  }
}
.elpagination {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  padding-right: 20px;
  align-items: center;
  .elpagination-title {
    margin-right: 4px;
  }
}
</style>
